import Enum from "@/util/enum";
import EnumValue from ".";

export default class MgPermissibility extends Enum {
  static UNKNOWN: EnumValue = { name: "", code: 0 };
  static P: EnumValue = { name: "Permissive", code: 1 };
  static G: EnumValue = { name: "Non-permissive GvH", code: 2 };
  static H: EnumValue = { name: "Non-permissive HvG", code: 3 };
  static A: EnumValue = { name: "Ambiguous", code: 4 };
}

/*
0 - Unknown
1 - P Permissive
2 - G Non-permissive in GvH direction
3 - H Non-permissive in HvG direction
4 - A Ambiguous
*/
