import EnumValue from ".";
import Enum from "@/util/enum";

export default class ReqType extends Enum {
  static NEW: EnumValue = { name: "New Request", code: 0 };
  static TYPREQ: EnumValue = { name: "Typing Request", code: 1 };
  static SMPREQ: EnumValue = { name: "Sample Request", code: 2 };
  static RESREQ: EnumValue = { name: "Reservation Request", code: 3 };
  static WORKUP: EnumValue = { name: "Workup Request", code: 4 };
}

/*
0 - NEW (po založení requestu v TC - neplatí pro rezervaci, ta bude rovnou RESREQ),
1 - TYPREQ,
2 - SMPREQ,
3 - RESREQ,
4 - WORKUP
*/
