
import { Options, Vue } from "vue-class-component";
import AtaTmpPatUtil from "@/util/ataTmpPatUtil";
import AtaTmpPat from "@/model/ataTmpPat";
import RecommendedDonor from "@/model/recommendedDonor";
import AtaEmdReq from "@/model/ataEmdReq";
import ReqDonWrapper from "@/util/reqDonWrapper";
import MoreDonorResponse from "@/model/moreDonorResponse";
import EmdisDonorState from "@/model/enums/emdisDonorState";
import Gender from "@/model/enums/gender";
import Cmv from "@/model/enums/cmv";
import Abo from "@/model/enums/abo";
import Rh from "@/model/enums/rh";
import MgPermissibility from "@/model/enums/mgPermissibility";
import DonorType from "@/model/enums/donorType";
import ReqStatus from "@/model/enums/reqStatus";
import ReqType from "@/model/enums/reqType";
import LineMenu from "@/components/LineMenu.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Dropdown from "primevue/dropdown";
import InlineMessage from "primevue/inlinemessage";
import SplitButton from "primevue/splitbutton";
import Dialog from "primevue/dialog";
import ProgressSpinner from "primevue/progressspinner";
import RecRequest from "@/components/RecRequest.vue";
import { namespace } from "vuex-class";
const recommendedDonors = namespace("recommendedDonors");

@Options({
  components: {
    DataTable,
    Column,
    Dropdown,
    LineMenu,
    InlineMessage,
    SplitButton,
    Dialog,
    RecRequest,
    ProgressSpinner,
  },
})
export default class RecommendedDonors extends Vue {
  @recommendedDonors.State
  pat!: AtaTmpPat;
  @recommendedDonors.State
  donors!: ReqDonWrapper[];
  @recommendedDonors.State
  loading!: boolean;
  @recommendedDonors.State
  pdfs!: MoreDonorResponse[];
  tmp!: ReqDonWrapper; //Used for passing values to dialogs (Show Match etc.)
  expandedRows = [];
  showMatchDialog = false;

  ReqType = ReqType;
  ReqStatus = ReqStatus;
  EmdisDonorState = EmdisDonorState;
  DonorType = DonorType;
  Gender = Gender;
  Cmv = Cmv;
  Abo = Abo;
  Rh = Rh;
  MgPermissibility = MgPermissibility;

  onRowExpand({ data }: { data: ReqDonWrapper }) {
    this.getRequests(data);
  }

  getButtonsModel(don: ReqDonWrapper) {
    return [
      {
        label: "Transplation",
        command: () => {
          this.transplation(don);
        },
      },
    ];
  }

  get parsePdf() {
    const out: { label: string; command: () => void }[] = [];
    this.pdfs.forEach((pdf) => {
      out.push({
        label: pdf.filename,
        command: () => {
          this.moreDonors(pdf);
        },
      });
    });
    return out;
  }

  parseDna(o: AtaTmpPat | AtaEmdReq, type: string) {
    return {
      type: type,
      dnaRequest: {
        a1: o.a1,
        b1: o.b1,
        c1: o.c1,
        drb11: o.drb11,
        drb31: o.drb31,
        drb41: o.drb41,
        drb51: o.drb51,
        dqb11: o.dqb11,
        dqa11: o.dqa11,
        dpb11: o.dpb11,
        dpa11: o.dpa11,
        a2: o.a2,
        b2: o.b2,
        c2: o.c2,
        drb12: o.drb12,
        drb32: o.drb32,
        drb42: o.drb42,
        drb52: o.drb52,
        dqb12: o.dqb12,
        dqa12: o.dqa12,
        dpb12: o.dpb12,
        dpa12: o.dpa12,
      },
      hlaRequest: {
        hlaa1: "",
        hlab1: "",
        hlac1: "",
        hladr1: "",
        hladq1: "",
        hladrw1: "",
        hlaa2: "",
        hlab2: "",
        hlac2: "",
        hladr2: "",
        hladq2: "",
        hladrw2: "",
      },
    } as {
      type: string;
      dnaRequest: RecommendedDonor["dnaRequest"];
      hlaRequest: RecommendedDonor["hlaRequest"];
    };
  }

  showMatchData() {
    const out = [this.parseDna(this.pat, "Patient"), this.tmp.don];
    if (this.tmp.sample && this.tmp.sample.id != null)
      out.push(this.parseDna(this.tmp.sample, "Request Sample"));
    return out;
  }

  moreDonors(pdf?: MoreDonorResponse) {
    if (!pdf && this.pdfs) {
      pdf = this.pdfs[0];
      this.pdfs.forEach(
        (el) => (pdf = !pdf || el.serial > pdf.serial ? el : pdf)
      );
    }
    // console.log("More donors: " + JSON.stringify(pdf));
    if (pdf) this.getMoreDonor(pdf);
  }

  async showMatch(don: ReqDonWrapper) {
    // console.log("Show Match: " + JSON.stringify(don));
    this.tmp = don;
    await this.getRequestsSample(this.tmp);
    this.showMatchDialog = true;
  }

  transplation(don: ReqDonWrapper) {
    console.log("Transplation: " + don.don.grid);
  }

  dnaColor(mg: number) {
    const o = "color:";
    switch (mg) {
      case 2: //ALLELE_POTENTIAL(2)
        return o + "blue";
      case 3: //BROAD_MATCHED(3)
        return o + "blue";
      case 4: //ALLELE_MISMATCH(4)
        return o + "red";
      case 5: //SPLIT_MISMATCH(5)
        return o + "red";
      case 6: //BROAD_MISMATCH(6)
        return o + "red";
      case 0: //UNCOUNTABLE(0)
      case 1: //ALLELE_MATCH(1)
      default:
        return "";
    }
  }

  getGrid(don: ReqDonWrapper) {
    return don.don.grid ? don.don.grid : don.don.donorid;
  }

  getMatch(d: ReqDonWrapper) {
    let grade = 0;
    let max = 0;
    // console.log(d);
    [
      d.don.mga1,
      d.don.mga2,
      d.don.mgb1,
      d.don.mgb2,
      d.don.mgc1,
      d.don.mgc2,
      d.don.mgdr1,
      d.don.mgdr2,
      d.don.mgdq1,
      d.don.mgdq2,
    ].forEach((mg) => {
      grade += [4, 5, 6].includes(mg) ? 1 : 0;
      max += 0 == mg ? 0 : 1;
    });

    return `${max - grade}/${max}`;
  }

  getMMLocus(d: ReqDonWrapper) {
    const out: string[] = [];
    // console.log(d);
    const v = new Map<string, number>();
    v.set("a1", d.don.mga1);
    v.set("a2", d.don.mga2);
    v.set("b1", d.don.mgb1);
    v.set("b2", d.don.mgb2);
    v.set("c1", d.don.mgc1);
    v.set("c2", d.don.mgc2);
    v.set("dr1", d.don.mgdr1);
    v.set("dr2", d.don.mgdr2);
    v.set("dq1", d.don.mgdq1);
    v.set("dq2", d.don.mgdq2);

    v.forEach((v, k) => {
      [4, 5, 6].includes(v) ? out.push(k.toUpperCase()) : null;
    });

    return out.join(", ");
  }

  getDna(dna: string, dna2: string, hla: string) {
    return dna ? dna : dna2 ? "" : hla;
  }

  getAge(birthdate: Date) {
    const date = AtaTmpPatUtil.parseDate(birthdate);
    if (!date) return "";
    const today = new Date();
    let y = today.getFullYear() - date.getFullYear();
    if (
      date.getMonth() > today.getMonth() ||
      (date.getMonth() == today.getMonth() && date.getDate() > today.getDate())
    )
      y--;
    // console.log(`${today.toUTCString()} - ${date.toUTCString()} = ${y}`);
    return y;
  }

  getAboRh(abo: string, rh: string) {
    if (abo == Abo.UNKNOWN.code || rh == Rh.UNKNOWN.code) return "";
    return abo + rh;
  }

  formatDate(date: string): string {
    if (!date) return "";
    const d = new Date(date);
    return `${d.getUTCFullYear()}-${d.getUTCMonth() + 1}-${d.getUTCDate()}`;
  }

  @recommendedDonors.Action
  getMoreDonor!: (pdf: MoreDonorResponse) => void;

  @recommendedDonors.Action
  getRequests!: (don: ReqDonWrapper) => void;

  @recommendedDonors.Action
  getRequestsSample!: (don: ReqDonWrapper) => void;
}
