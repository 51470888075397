import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-15023466"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "header" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "p-grid p-ai-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Button, _mergeProps({ onClick: _ctx.show }, _ctx.buttonBind), null, 16, ["onClick"]),
    _createVNode(_component_Dialog, {
      visible: _ctx.visible,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.visible) = $event)),
      modal: ""
    }, {
      header: _withCtx(() => [
        _createElementVNode("b", _hoisted_1, _toDisplayString(_ctx.header), 1)
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Button, {
            label: "Confirm",
            onClick: _ctx.confirm,
            class: "p-button-rounded p-button-success"
          }, null, 8, ["onClick"]),
          _createVNode(_component_Button, {
            label: "Cancel",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.visible = false)),
            class: "p-button-rounded p-button-outlined p-button-secondary"
          })
        ])
      ]),
      default: _withCtx(() => [
        (_ctx.mode == _ctx.ReqType.NEW)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, "Do you want to send a request?"))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, "Confirm reservation request?"))
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}